@tailwind base;
@tailwind components;
@tailwind utilities;

.fumadocs {
  --fd-nav-height: 76px;
  --fd-muted-foreground: 225.88deg 7.42% 55.1%;
  --background: 0deg 0% 100%;
  --foreground: 255deg 14.71% 21%;
  color: hsl(var(--foreground) / 1);
}

.dark .fumadocs {
  --background: 0deg 0% 7.06%;
  --foreground: 0deg 0% 85%;
}

.fumadocs * {
  border-color: hsl(var(--fd-border) / 1);
}

.fumadocs .border,
[data-toc-popover].border {
  border-color: hsl(var(--fd-border) / 1) !important;
  border-width: 1px !important;
}

@media (min-width: 1441px) {
  /* docs pages have a wider container */
  body:has(.container-docs) .container-xl {
    max-width: 105rem !important;
  }
}

/* prose */

.fumadocs article a,
#nd-breadcrumb a {
  --tw-prose-links: hsl(267.1deg 100% 63.53%);
  color: var(--tw-prose-links);
  text-decoration: none;
}

.dark .fumadocs article a,
.dark #nd-breadcrumb a {
  --tw-prose-links: hsl(164.47deg 100% 50%);
}

.fumadocs .prose a:hover {
  text-decoration: underline;
}

.fumadocs .prose :is(h1, h2, h3, h4, h5, h6) a {
  color: inherit;
}
.fumadocs
  .prose
  :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: 1.25em;
}

.fumadocs article {
  padding-top: 2rem !important;
}

/* table of contents */

.fumadocs #nd-tocnav {
  background: hsl(var(--background) / 0.9);
  border-bottom: none;
  backdrop-filter: none;
  color: hsl(var(--fd-muted-foreground) / 1);
}

.fumadocs #nd-tocnav code {
  color: inherit;
}

.fumadocs #nd-toc {
  padding-top: 1.5rem !important;
}

.fumadocs #nd-toc :is(a, h3, button) {
  @apply text-base;
}

/* sidebar */

.fumadocs #fd-sidebar-toggle button {
  background: hsl(var(--background));
  box-shadow: 0 0 6px 0 hsl(var(--foreground) / 1);
  scale: 1.25;
}

.fumadocs #nd-sidebar [data-radix-scroll-area-viewport] {
  mask-image: none !important;
}

.fumadocs #nd-sidebar :is(a, button) {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.2s;
}

.scrollbar-thin:hover {
  scrollbar-color: hsl(var(--border) / 1) transparent;
}

/* accordion */

.group\/accordion h3.not-prose {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0;
  color: hsl(var(--fd-muted-foreground) / 1);
}

.prose-no-margin > :first-child {
  margin-top: 0 !important;
}

.prose-no-margin > :last-child {
  margin-bottom: 0 !important;
}

/* code */

.fumadocs pre {
  margin-bottom: 0;
}

.fumadocs code {
  font-size: 14px !important;
}

.fumadocs .group:hover .group-hover\:opacity-100 {
  opacity: 1 !important;
}

/* previous/next links */

.fumadocs article > div:not(.prose) p {
  margin-bottom: 0;
}
